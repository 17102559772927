import {createWebHistory, createRouter} from "vue-router"
import Home from "@/components/Home"
import About from "@/components/pages/About"
import Terms from "@/components/pages/Terms"
import Policy from "@/components/pages/Policy"

const routes = [
    {
        path: "/",
        name: "Home",
        component:Home
    },
    {
        path: "/about-us",
        name: "about-us",
        component:About
    },
    {
        path: "/terms-of-service",
        name: "terms-of-service",
        component:Terms
    },
    {
        path: "/privacy-policy",
        name: "privacy-policy",
        component:Policy
    }

]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router
