export default {
  "Download YouTube videos for free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar videos de YouTube gratis"])},
  "Best free tool to download videos from YouTube": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La mejor herramienta gratuita para descargar videos de YouTube"])},
  "Search or paste Youtube link here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Busque o pegue el enlace de Youtube aquí"])},
  "Start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comienzo"])},
  "The best online YouTube video downloader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El mejor descargador de videos de YouTube en línea"])},
  "Free tool to help you download video from Youtube to your computer for free. In addition, it supports converting to many different formats and qualities for download.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herramienta gratuita para ayudarlo a descargar videos de Youtube a su computadora de forma gratuita. Además, admite la conversión a muchos formatos y calidades diferentes para descargar."])},
  "Quick and easy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapido y Facil"])},
  "Our Youtube video downloader helps you quickly search and download any Youtube video to formats like: MP4, WEBM, M4A ...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuestro descargador de videos de Youtube lo ayuda a buscar y descargar rápidamente cualquier video de Youtube a formatos como: MP4, WEBM, M4A ..."])},
  "Unlimited YouTube downloads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargas ilimitadas de YouTube"])},
  "Support downloading and converting unlimited Youtube videos with the best transfer speed up to 1GB/s.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admite la descarga y conversión ilimitada de videos de Youtube con la mejor velocidad de transferencia de hasta 1 GB/s."])},
  "Safety and Security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguridad y proteccion"])},
  "The converted and downloaded files are kept the same quality and content as YouTube's.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los archivos convertidos y descargados se mantienen con la misma calidad y contenido que los de YouTube."])},
  "Support all devices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admite todos los dispositivos"])},
  "a website-based Youtube video downloader that works well on all platforms such as Windows, Mac or Linux, Android, iPhone ... without downloading or installing any software.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["un descargador de videos de Youtube basado en un sitio web que funciona bien en todas las plataformas como Windows, Mac o Linux, Android, iPhone... sin descargar ni instalar ningún software."])},
  "Supports many formats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soporta muchos formatos"])},
  "Our tool supports converting many video and audio formats. You can easily convert YouTube videos to 3GP, MP4, WMA, M4A, FLV, WEBM and MO formats, etc.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuestra herramienta admite la conversión de muchos formatos de video y audio. Puede convertir fácilmente videos de YouTube a formatos 3GP, MP4, WMA, M4A, FLV, WEBM y MO, etc."])},
  "Free forever": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siempre libre"])},
  "The tool is 100% free and always will be. You can support us by sending and sharing this tool with your friends. Thank you!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La herramienta es 100% gratuita y siempre lo será. Puedes apoyarnos enviando y compartiendo esta herramienta con tus amigos. ¡Gracias!"])},
  "Online YouTube video downloader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargador de videos de YouTube en línea"])},
  "Download Youtube videos easily with the \"Youtube Downloader\" tool. Support downloading and convert YouTube video with the best quality to formats MP4, 3GP, WEBM, M4A ... fast and completely free. This YouTube downloader application is compatible with all devices such as: computers, tablets and mobile phones ...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargue videos de Youtube fácilmente con la herramienta \"Youtube Downloader\". Admite la descarga y conversión de videos de YouTube con la mejor calidad a formatos MP4, 3GP, WEBM, M4A... rápido y completamente gratis. Esta aplicación de descarga de YouTube es compatible con todos los dispositivos, tales como: computadoras, tabletas y teléfonos móviles ..."])},
  "The easiest YouTube video download guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La guía de descarga de videos de YouTube más fácil"])},
  "Paste the YouTube link or enter keywords in the search box": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pegue el enlace de YouTube o ingrese palabras clave en el cuadro de búsqueda"])},
  "Select the output format you want to transfer and click the \"Download\" button.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione el formato de salida que desea transferir y haga clic en el botón \"Descargar\"."])},
  "Wait a few seconds for the conversion to complete and download the file. Simple, easy to use.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espere unos segundos para que se complete la conversión y descargue el archivo. Sencillo, fácil de usar."])},
  "Is youtube downloader have a limit of usage?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿El descargador de youtube tiene un límite de uso?"])},
  "Absolutely NO. Our tool allows you to convert and download in unlimited quantities and all for free.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absolutamente no. Nuestra herramienta le permite convertir y descargar en cantidades ilimitadas y todo gratis."])},
  "Can I download videos on mobile?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Puedo descargar videos en el móvil?"])},
  "Our tool works well on all devices such as PCs, smartphones and tablets.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuestra herramienta funciona bien en todos los dispositivos, como PC, teléfonos inteligentes y tabletas."])},
  "Where is the Youtube video stored after downloading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Dónde se almacena el video de Youtube después de la descarga?"])},
  "Please check the \"Downloads\" folder in your phone or the \"download history\" section of your browser.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulte la carpeta \"Descargas\" en su teléfono o la sección \"Historial de descargas\" de su navegador."])},
  "Frequently Asked Questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preguntas frecuentes"])},
  "What languages support ?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Qué idiomas soportan?"])},
  "We support download Youtube video in all popular languages today.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apoyamos la descarga de videos de Youtube en todos los idiomas populares hoy."])},
  "About": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acerca de"])},
  "Terms of Service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Términos de servicio"])},
  "Privacy Policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de privacidad"])},
  "Contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacto"])}
}