<template>
<button :ref="'btn_'+video.id" class="btn btn-success w-100 d-none" data-toggle="modal" :data-target="'#preview_'+video.id">Preview btn</button>
  <!-- Modal -->
   <div class="modal fade" :id="'preview_'+video.id" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
     <div class="modal-dialog" role="document">
       <div class="modal-content">
         <div class="modal-header">
           <h5 class="modal-title">{{video.title}}</h5>
            
              <button @click="stopVideo(video.id)" type="button" class="close">
               <span aria-hidden="true">&times;</span>
             </button>
         </div>
         <div class="modal-body">
        <div :id="'ifram_'+video.id">
            <iframe   allowfullscreen="true" style="background: url('./assets/img/spinner-3.gif');background-repeat:no-repeat;background-position: center center"   width="100%" height="300px" :src="'https://www.youtube.com/embed/'+video.id" ></iframe>
        </div>
         </div>
         <div class="modal-footer">
           <p :class="{'rtl':$i18n.locale == 'ar'}" v-if="!loaded" class="text-center text-muted d-block w-100" >Loading links ...</p>
            <select v-if="loaded" ref="selected_video" class="form-control form-control-small" id="formatSelect">
                 <optgroup v-for="format in  Object.keys(info)" v-bind:key="format" :label="format">
                          <option v-for="ext in  info[format] " :url="ext.url" :filename="`${video.id}_${ext.itag}.${format}`"  v-bind:key="ext.itag" >{{`${format} ${getLabelQuality(ext.qualityLabel)} ${ext.quality} ${niceBytes(ext.contentLength)}`}}</option>
                  </optgroup>
              </select>
           <button @click="dowbloadVideo()" v-if="loaded" type="button" class="btn btn-primary w-100">
             Download
             </button>
         </div>
       </div>
     </div>
   </div>
</template>

<script>
  import * as $ from  "jquery"
  const API_URL = window.location.protocol+'//'+window.location.hostname+':'+window.location.port+'/'

export default {
  name: 'Preview',
  data() {
      return {
        API_URL:''
      }
  },
  props: {
    video: Object,
    loaded : Boolean,
    info : Object
  },
  methods: {
  
  stopVideo(id){
      let containerElement = document.getElementById('ifram_'+id);
    let iframe_tag = containerElement.querySelector( 'iframe');
    let video_tag = containerElement.querySelector( 'video' );
    if ( iframe_tag) {
        let iframeSrc = iframe_tag.src;
        iframe_tag.src = iframeSrc; 
    }
    if ( video_tag) {
        video_tag.pause();
    }
        $('#preview_'+this.video.id).modal('hide')
  },
  dowbloadVideo(){
    let options = this.$refs.selected_video.options
    let url = options[options.selectedIndex].getAttribute('url');
    let filename = options[options.selectedIndex].getAttribute('filename');
    if(this.isValidLink(url))
    {
     url = encodeURIComponent(url)
     window.open(this.API_URL + `download-video/${url}/${filename}`, "_blank")
    }
  },
    niceBytes(x){
    if(x)
    {
      const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      let l = 0, n = parseInt(x, 10) || 0;
      while(n >= 1024 && ++l){
          n = n/1024;
      }
      return(n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
    }
    return '';
  },
  isValidLink(string) {
    let url;
    
    try {
      url = new URL(string);
    } catch (_) {
      return false;  
    }
  
    return url.protocol === "http:" || url.protocol === "https:";
  },
  getLabelQuality(label)
  {
    return label ? label : ''
  }
  },
  mounted() {
  this.API_URL = this.ENVIRONNEMENT === "prod" ? API_URL : this.API_ROOT_DEV
  $('#preview_'+this.video.id).modal('show')
    
  },
}
</script>

<style lang="scss" scoped>

</style>
