export default {
  "Download YouTube videos for free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unduh video YouTube secara gratis"])},
  "Best free tool to download videos from YouTube": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alat gratis terbaik untuk mengunduh video dari YouTube"])},
  "Search or paste Youtube link here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cari atau tempel tautan Youtube di sini"])},
  "Start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Awal"])},
  "The best online YouTube video downloader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengunduh video YouTube online terbaik"])},
  "Free tool to help you download video from Youtube to your computer for free. In addition, it supports converting to many different formats and qualities for download.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alat gratis untuk membantu Anda mengunduh video dari Youtube ke komputer Anda secara gratis. Selain itu, ini mendukung konversi ke berbagai format dan kualitas untuk diunduh."])},
  "Quick and easy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cepat dan mudah"])},
  "Our Youtube video downloader helps you quickly search and download any Youtube video to formats like: MP4, WEBM, M4A ...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengunduh video Youtube kami membantu Anda dengan cepat mencari dan mengunduh video Youtube apa pun ke format seperti: MP4, WEBM, M4A ..."])},
  "Unlimited YouTube downloads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unduhan YouTube tanpa batas"])},
  "Support downloading and converting unlimited Youtube videos with the best transfer speed up to 1GB/s.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mendukung pengunduhan dan konversi video Youtube tanpa batas dengan kecepatan transfer terbaik hingga 1GB/dtk."])},
  "Safety and Security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keselamatan dan keamanan"])},
  "The converted and downloaded files are kept the same quality and content as YouTube's.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berkas yang dikonversi dan diunduh tetap memiliki kualitas dan konten yang sama dengan milik YouTube."])},
  "Support all devices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mendukung semua perangkat"])},
  "a website-based Youtube video downloader that works well on all platforms such as Windows, Mac or Linux, Android, iPhone ... without downloading or installing any software.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pengunduh video Youtube berbasis situs web yang bekerja dengan baik di semua platform seperti Windows, Mac atau Linux, Android, iPhone ... tanpa mengunduh atau menginstal perangkat lunak apa pun."])},
  "Supports many formats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mendukung banyak format"])},
  "Our tool supports converting many video and audio formats. You can easily convert YouTube videos to 3GP, MP4, WMA, M4A, FLV, WEBM and MO formats, etc.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alat kami mendukung konversi banyak format video dan audio. Anda dapat dengan mudah mengonversi video YouTube ke format 3GP, MP4, WMA, M4A, FLV, WEBM dan MO, dll."])},
  "Free forever": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bebas selamanya"])},
  "The tool is 100% free and always will be. You can support us by sending and sharing this tool with your friends. Thank you!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alat ini 100% gratis dan akan selalu begitu. Anda dapat mendukung kami dengan mengirimkan dan membagikan alat ini kepada teman-teman Anda. Terima kasih!"])},
  "Online YouTube video downloader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengunduh video YouTube online"])},
  "Download Youtube videos easily with the \"Youtube Downloader\" tool. Support downloading and convert YouTube video with the best quality to formats MP4, 3GP, WEBM, M4A ... fast and completely free. This YouTube downloader application is compatible with all devices such as: computers, tablets and mobile phones ...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unduh video Youtube dengan mudah dengan alat \"Youtube Downloader\". Mendukung pengunduhan dan konversi video YouTube dengan kualitas terbaik ke format MP4, 3GP, WEBM, M4A ... cepat dan sepenuhnya gratis. Aplikasi pengunduh YouTube ini kompatibel dengan semua perangkat seperti: komputer, tablet, dan ponsel ..."])},
  "The easiest YouTube video download guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panduan pengunduhan video YouTube termudah"])},
  "Paste the YouTube link or enter keywords in the search box": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempel tautan YouTube atau masukkan kata kunci di kotak pencarian"])},
  "Select the output format you want to transfer and click the \"Download\" button.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih format output yang ingin Anda transfer dan klik tombol \"Unduh\"."])},
  "Wait a few seconds for the conversion to complete and download the file. Simple, easy to use.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tunggu beberapa detik hingga konversi selesai dan unduh file. Sederhana, mudah digunakan."])},
  "Is youtube downloader have a limit of usage?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apakah pengunduh youtube memiliki batas penggunaan?"])},
  "Absolutely NO. Our tool allows you to convert and download in unlimited quantities and all for free.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benar-benar TIDAK. Alat kami memungkinkan Anda untuk mengonversi dan mengunduh dalam jumlah tak terbatas dan semuanya gratis."])},
  "Can I download videos on mobile?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bisakah saya mengunduh video di ponsel?"])},
  "Our tool works well on all devices such as PCs, smartphones and tablets.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alat kami berfungsi dengan baik di semua perangkat seperti PC, ponsel cerdas, dan tablet."])},
  "Where is the Youtube video stored after downloading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Di mana video Youtube disimpan setelah diunduh"])},
  "Please check the \"Downloads\" folder in your phone or the \"download history\" section of your browser.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silakan periksa folder \"Unduhan\" di ponsel Anda atau bagian \"riwayat unduhan\" di browser Anda."])},
  "Frequently Asked Questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pertanyaan yang Sering Diajukan"])},
  "What languages support ?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahasa apa yang mendukung?"])},
  "We support download Youtube video in all popular languages today.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kami mendukung pengunduhan video Youtube dalam semua bahasa populer saat ini."])},
  "About": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tentang"])},
  "Terms of Service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persyaratan Layanan"])},
  "Privacy Policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rahasia pribadi"])},
  "Contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontak"])}
}