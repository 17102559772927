<template>
  <router-view></router-view>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.min.css'
import 'jquery/src/jquery.js'
import 'bootstrap/dist/js/bootstrap.min.js'
export default {
  name: 'App',
  created() {
            document.title = this.WEBSITE_NAME;
            document.documentElement.setAttribute('lang',this.$i18n.locale)
  },
  mounted() {
    let lang = localStorage.getItem('lang');
    if(lang) this.$i18n.locale = lang
  },
}
</script>

<style>
  .rtl{
    text-align: right !important;
    direction: rtl !important;
  }
</style>
