<template>
  <div class="container container-main">
    <Search></Search>
    <div class="ftco-section center">
        <h2  class=title>{{$t('The best online YouTube video downloader')}}</h2>
        <p  class=mw70>{{$t('Free tool to help you download video from Youtube to your computer for free. In addition, it supports converting to many different formats and qualities for download.')}}</p>
        <ul class="listitem items-mvore">
            <li>
                <div class="post-content">
                    <h3 :class="{'rtl':$i18n.locale == 'ar'}" ><img  class="mx-2" src="./../assets/img/rocket.png">{{$t('Quick and easy')}}</h3>
                    <p :class="{'rtl':$i18n.locale == 'ar'}">{{$t('Our Youtube video downloader helps you quickly search and download any Youtube video to formats like: MP4, WEBM, M4A ...')}}</p>
                </div>
            </li>
            <li>
                <div class="post-content">
                    <h3 :class="{'rtl':$i18n.locale == 'ar'}" ><img class="mx-2" src="./../assets/img/limit.png">{{$t('Unlimited YouTube downloads')}}</h3>
                    <p :class="{'rtl':$i18n.locale == 'ar'}">{{$t('Support downloading and converting unlimited Youtube videos with the best transfer speed up to 1GB/s.')}}</p>
                </div>
            </li>
            <li>
                <div class="post-content">
                    <h3 :class="{'rtl':$i18n.locale == 'ar'}" ><img class="mx-2" src="./../assets/img/security.png">{{$t('Safety and Security')}}</h3>
                    <p :class="{'rtl':$i18n.locale == 'ar'}">{{$t("The converted and downloaded files are kept the same quality and content as YouTube's.")}}</p>
                </div>
            </li>
            <li>
                <div class="post-content">
                    <h3 :class="{'rtl':$i18n.locale == 'ar'}" ><img class="mx-2" src="./../assets/img/chrome.png">{{$t("Support all devices")}}</h3>
                    <p :class="{'rtl':$i18n.locale == 'ar'}">{{$t("a website-based Youtube video downloader that works well on all platforms such as Windows, Mac or Linux, Android, iPhone ... without downloading or installing any software.")}}</p>
                </div>
            </li>
            <li>
                <div class="post-content">
                    <h3 :class="{'rtl':$i18n.locale == 'ar'}" ><img class="mx-2" src="./../assets/img/support.png">{{$t("Supports many formats")}}</h3>
                    <p :class="{'rtl':$i18n.locale == 'ar'}">{{$t("Our tool supports converting many video and audio formats. You can easily convert YouTube videos to 3GP, MP4, WMA, M4A, FLV, WEBM and MO formats, etc.")}}</p>
                </div>
            </li>
            <li>
                <div class="post-content">
                    <h3 :class="{'rtl':$i18n.locale == 'ar'}" ><img class="mx-2" src="./../assets/img/free.png">{{$t("Free forever")}}</h3>
                    <p :class="{'rtl':$i18n.locale == 'ar'}">{{$t("The tool is 100% free and always will be. You can support us by sending and sharing this tool with your friends. Thank you!")}}</p>
                </div>
            </li>
        </ul>
    </div>
    <div class="ftco-section center">
        <h2 class=title>{{$t("Online YouTube video downloader")}}</h2>
        <ul class=listicon>
            <li> <img src="./../assets/img/icon1.svg" alt="Tool convert Youtube to MP4"> </li>
            <li> <img src="./../assets/img/icon3.svg" alt="Tool convert Youtube to 3GP"> </li>
            <li> <img src="./../assets/img/icon4.svg" alt="Tool convert Youtube to WEBM"> </li>
            <li> <img src="./../assets/img/icon5.svg" alt="Tool convert Youtube to M4A"> </li>
        </ul>
        <p :class="{'rtl':$i18n.locale == 'ar'}" class=mw70>{{$t("Download Youtube videos easily with the \"Youtube Downloader\" tool. Support downloading and convert YouTube video with the best quality to formats MP4, 3GP, WEBM, M4A ... fast and completely free. This YouTube downloader application is compatible with all devices such as: computers, tablets and mobile phones ...")}}</p>
    </div>
    <div class="ftco-section center">
        <h2 class=title>{{$t("The easiest YouTube video download guide")}}</h2>
        <ul :class="{'rtl':$i18n.locale == 'ar'}" class="listicon liststep">
            <li> <span class="number mx-2">1</span> <span :class="{'rtl':$i18n.locale == 'ar'}">{{$t("Paste the YouTube link or enter keywords in the search box")}}</span> </li>
            <li> <span class="number  mx-2">2</span> <span :class="{'rtl':$i18n.locale == 'ar'}">{{$t("Select the output format you want to transfer and click the \"Download\" button.")}}</span> </li>
            <li> <span class="number  mx-2">3</span> <span :class="{'rtl':$i18n.locale == 'ar'}">{{$t("Wait a few seconds for the conversion to complete and download the file. Simple, easy to use.")}}</span> </li>
        </ul>
    </div>
    <div class="ftco-section section-left">
            <h2 class="title text-center">{{$t("Frequently Asked Questions")}}</h2>
        <div :class="{'rtl':$i18n.locale == 'ar'}" class="ftco-section">
            <h3>{{$t("Is youtube downloader have a limit of usage?")}}</h3>
            <p  class="p-2">
                {{$t("Absolutely NO. Our tool allows you to convert and download in unlimited quantities and all for free.")}}
            </p>
            <h3>{{$t("Can I download videos on mobile?")}}</h3>
            <p  class="p-2">
                {{$t("Our tool works well on all devices such as PCs, smartphones and tablets.")}}
            </p> 
            <h3>{{$t("Where is the Youtube video stored after downloading")}}</h3>
            <p  class="p-2">
                {{$t("Please check the \"Downloads\" folder in your phone or the \"download history\" section of your browser.")}}
            </p> 
            <h3>{{$t("What languages support ?")}}</h3>
            <p  class="p-2">
                {{$t("We support download Youtube video in all popular languages today.")}}
            </p> 
        
        </div>
   </div>
</div>
</template>

<script>
import Search from "./Search"
export default {
  name: 'Content',
  components:{
      Search
  },
    mounted() {
    },
}
</script>

<style lang="scss" scoped>
 .post-content {
     h3{
         font-size:1.20em
     }
 }

</style>