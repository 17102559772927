<template>
    <footer>
    <div class=copyright>
        <ul class="flex flex-a-i-center">
            <li>
                <p :class="{'rtl':$i18n.locale == 'ar'}">&copy; {{new Date().getFullYear()}}  {{WEBSITE_NAME}}</p>
            </li>
            <li><router-link to="about-us">{{$t('About')}}</router-link> </li>
            <li><router-link to="terms-of-service">{{$t('Terms of Service')}}</router-link> </li>
            <li><router-link to="privacy-policy">{{$t('Privacy Policy')}}</router-link> </li>
            <li><a href="#">{{$t('Contact')}}</a></li>
        </ul>
    </div>
</footer>
</template>

<script>
export default {
  name: 'Footer',
}
</script>

<style lang="scss" scoped>

</style>