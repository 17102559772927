<template>
 <div  @click="loadModal(video)" >
   <div  class="item w-100 border mb-3 shadow-sm">
    <img  :src="video.thumbnails && video.thumbnails.length ? video.thumbnails[video.thumbnails.length - 1]['url'] : '#'" class="w-100">
     <div    class="mt-1 d-flex justify-content-between">
       <h5  :class="{'rtl':$i18n.locale == 'ar'}" class="text-left p-2" >{{video.title}}</h5>
     </div>
      <Preview  v-if="Object.keys(selected_video).length" :info="info" :loaded="loaded" :video="video"></Preview>
   </div>
 </div>
</template>

<script>
import Preview from "./Preview.vue";
import * as $ from  "jquery"
const axios = require('axios')
const API_URL = window.location.protocol+'//'+window.location.hostname+':'+window.location.port+'/'

export default {
  name: 'VideoElement',
  data() {
    return {
      open_preview: {},
      selected_video: {},
      loaded : false,
      info : {},
      API_URL: ''

    }
  },
  computed:{
  },
  components:{Preview},
   props: {
    video: Object
  },
  mounted(){
   this.API_URL = this.ENVIRONNEMENT === "prod" ? API_URL : this.API_ROOT_DEV
  },
  methods: {
   loadDownloadFormats(){
     axios.get(this.API_URL + 'get-video-info/' + this.video.id).then(res => {
       if(res)
       {
         this.info = res.data
         this.loaded = true
       }
         })
  },
 loadModal(video){
   this.selected_video = video
    $('#preview_'+this.video.id).modal('show')
      this.loadDownloadFormats()
 }
 



  }
}
</script>

<style lang="scss" scoped>
 .item{
   height: 300px;
   overflow:hidden;
   cursor:pointer;
 }
</style>