<template>
    <header id=navbar>
    <div class="container">
        <div class="topnav" id=myTopnav>
            <div class="nav-logo">
                <a @click="home()" href="#" class="navbar-brand waves-effect"> {{WEBSITE_NAME}}</a>
             </div>
            <div @click="openNav()"  class="nav-item" itemscope itemtype=http://www.schema.org/SiteNavigationElement>
                 <div class="dropdown">
                    <div class="dropbtn dropdown-title"> {{locales[$i18n.locale]}}
                        <svg xmlns=http://www.w3.org/2000/svg width=12 height=10 viewBox="0 0 16 8" fill=none>
                            <path d="M15.0449 0.291415C14.8464 0.0929657 14.5239 0.0929657 14.3255 0.291415L7.9999 6.6294L1.66192 0.291415C1.46347 0.0929657 1.14099 0.0929657 0.942538 0.291415C0.744089 0.489865 0.744089 0.812346 0.942538 1.0108L7.62781 7.69606C7.72703 7.79529 7.85107 7.8449 7.9875 7.8449C8.11153 7.8449 8.24796 7.79529 8.34719 7.69606L15.0325 1.0108C15.2433 0.812346 15.2433 0.489865 15.0449 0.291415Z"
                            fill=#ffffff></path>
                        </svg>
                    </div>
               <div class="dropdown-content"> 
                <a  @click="setLang(lang)" v-for="(lang, i) in Object.keys(locales)" :key="`locale-${i}`" class="dropdown-item"  href="#">{{locales[lang]}}</a> 
                 </div> 
                </div>
            </div>
        </div>
    </div>
</header>
</template>

<script>
import * as $ from 'jquery'
export default {
  name: 'Header',
  data() {
      return {
          current_lang : "English",
          locales: {
            "en" :"English",
            "ar" :"العربية",
            "tr" :"Türkçe",
            "hi" :"हिन्दी / Hindī",
            "fr" :"Français",
            "es" :"Español",
            "id" :"Indonesian",
            }
      }
  },
  methods: {
      openNav(){
            $(".topnav").toggleClass("responsive");
      },
      setLang(lang){
          this.$i18n.locale = lang;
          localStorage.setItem('lang',lang)
      },
      home(){
          document.location.reload()
      }
  }
}
</script>

<style lang="scss" scoped>

</style>