export default {
  "Download YouTube videos for free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download YouTube videos for free"])},
  "Best free tool to download videos from YouTube": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Best free tool to download videos from YouTube"])},
  "Search or paste Youtube link here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search or paste Youtube link here"])},
  "Start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
  "The best online YouTube video downloader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The best online YouTube video downloader"])},
  "Free tool to help you download video from Youtube to your computer for free. In addition, it supports converting to many different formats and qualities for download.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free tool to help you download video from Youtube to your computer for free. In addition, it supports converting to many different formats and qualities for download."])},
  "Quick and easy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quick and easy"])},
  "Our Youtube video downloader helps you quickly search and download any Youtube video to formats like: MP4, WEBM, M4A ...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Youtube video downloader helps you quickly search and download any Youtube video to formats like: MP4, WEBM, M4A ..."])},
  "Unlimited YouTube downloads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlimited YouTube downloads"])},
  "Support downloading and converting unlimited Youtube videos with the best transfer speed up to 1GB/s.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support downloading and converting unlimited Youtube videos with the best transfer speed up to 1GB/s."])},
  "Safety and Security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Safety and Security"])},
  "The converted and downloaded files are kept the same quality and content as YouTube's.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The converted and downloaded files are kept the same quality and content as YouTube's."])},
  "Support all devices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support all devices"])},
  "a website-based Youtube video downloader that works well on all platforms such as Windows, Mac or Linux, Android, iPhone ... without downloading or installing any software.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a website-based Youtube video downloader that works well on all platforms such as Windows, Mac or Linux, Android, iPhone ... without downloading or installing any software."])},
  "Supports many formats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supports many formats"])},
  "Our tool supports converting many video and audio formats. You can easily convert YouTube videos to 3GP, MP4, WMA, M4A, FLV, WEBM and MO formats, etc.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our tool supports converting many video and audio formats. You can easily convert YouTube videos to 3GP, MP4, WMA, M4A, FLV, WEBM and MO formats, etc."])},
  "Free forever": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free forever"])},
  "The tool is 100% free and always will be. You can support us by sending and sharing this tool with your friends. Thank you!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The tool is 100% free and always will be. You can support us by sending and sharing this tool with your friends. Thank you!"])},
  "Online YouTube video downloader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online YouTube video downloader"])},
  "Download Youtube videos easily with the \"Youtube Downloader\" tool. Support downloading and convert YouTube video with the best quality to formats MP4, 3GP, WEBM, M4A ... fast and completely free. This YouTube downloader application is compatible with all devices such as: computers, tablets and mobile phones ...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Youtube videos easily with the \"Youtube Downloader\" tool. Support downloading and convert YouTube video with the best quality to formats MP4, 3GP, WEBM, M4A ... fast and completely free. This YouTube downloader application is compatible with all devices such as: computers, tablets and mobile phones ..."])},
  "The easiest YouTube video download guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The easiest YouTube video download guide"])},
  "Paste the YouTube link or enter keywords in the search box": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paste the YouTube link or enter keywords in the search box"])},
  "Select the output format you want to transfer and click the \"Download\" button.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the output format you want to transfer and click the \"Download\" button."])},
  "Wait a few seconds for the conversion to complete and download the file. Simple, easy to use.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wait a few seconds for the conversion to complete and download the file. Simple, easy to use."])},
  "Is youtube downloader have a limit of usage?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is youtube downloader have a limit of usage?"])},
  "Absolutely NO. Our tool allows you to convert and download in unlimited quantities and all for free.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absolutely NO. Our tool allows you to convert and download in unlimited quantities and all for free."])},
  "Can I download videos on mobile?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can I download videos on mobile?"])},
  "Our tool works well on all devices such as PCs, smartphones and tablets.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our tool works well on all devices such as PCs, smartphones and tablets."])},
  "Where is the Youtube video stored after downloading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where is the Youtube video stored after downloading"])},
  "Please check the \"Downloads\" folder in your phone or the \"download history\" section of your browser.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check the \"Downloads\" folder in your phone or the \"download history\" section of your browser."])},
  "Frequently Asked Questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frequently Asked Questions"])},
  "What languages support ?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What languages support ?"])},
  "We support download Youtube video in all popular languages today.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["we support download Youtube video in all popular languages today."])},
  "About": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
  "Terms of Service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of Service"])},
  "Privacy Policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
  "Contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])}
}