import { createApp } from 'vue'
import App from './App.vue'
import i18n from './i18n'
import router from './router'
const app = createApp(App)

app.use(i18n);
app.use(router);

 // set global variables
 require('./helpers/constants')(app)

 app.mount('#app')
