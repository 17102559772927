..<template>
 <main>
  <Header></Header>
  <Content></Content>
  <Footer></Footer>
  </main>
</template>

<script>
import Header from '@/components/partials/Header'
import Footer from '@/components/partials/Footer'
import Content from '@/components/Content'

export default {
    name:"Home",
    components: {
    Header,
    Footer,
    Content
  }

}
</script>

<style>

</style>

