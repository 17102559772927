<template>
    <div class="ftco-section center" id="convert">
        <h1 class=title>{{$t('Download YouTube videos for free')}}</h1>
        <p>{{$t('Best free tool to download videos from YouTube')}}</p>
        <div id=search-form class="search-form relative">
            <input :class="{'text-right':$i18n.locale == 'ar'}"  v-model="search_input" id=s_input type=search name=q class="search__input" aria-label=Search
                :placeholder="$t('Search or paste Youtube link here')">
            <button v-on:click="getVideos()" id="start" class="btn-red" type="button">{{$t('Start')}}</button>
        </div>
        <div v-if="processing" id=loader-wrapper>
            <svg xmlns=http://www.w3.org/2000/svg xmlns:xlink=http://www.w3.org/1999/xlink
                style=margin:auto;background:#fff;display:block width=200px height=200px viewBox="0 0 100 100"
                preserveAspectRatio=xMidYMid>
                <g transform="translate(20 50)">
                    <circle cx=0 cy=0 r=6 fill=#e15b64>
                        <animateTransform attributeName=transform type=scale begin=-0.375s calcMode=spline
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values=0;1;0 keyTimes=0;0.5;1 dur=1s
                            repeatCount=indefinite></animateTransform>
                    </circle>
                </g>
                <g transform="translate(40 50)">
                    <circle cx=0 cy=0 r=6 fill=#f8b26a>
                        <animateTransform attributeName=transform type=scale begin=-0.25s calcMode=spline
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values=0;1;0 keyTimes=0;0.5;1 dur=1s
                            repeatCount=indefinite></animateTransform>
                    </circle>
                </g>
                <g transform="translate(60 50)">
                    <circle cx=0 cy=0 r=6 fill=#abbd81>
                        <animateTransform attributeName=transform type=scale begin=-0.125s calcMode=spline
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values=0;1;0 keyTimes=0;0.5;1 dur=1s
                            repeatCount=indefinite></animateTransform>
                    </circle>
                </g>
                <g transform="translate(80 50)">
                    <circle cx=0 cy=0 r=6 fill=#81a3bd>
                        <animateTransform attributeName=transform type=scale begin=0s calcMode=spline
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values=0;1;0 keyTimes=0;0.5;1 dur=1s
                            repeatCount=indefinite></animateTransform>
                    </circle>
                </g>
            </svg>
        </div>
        <div v-if="finished && !processing" id="search-result">
            <div class="col-md-12">
                <div class="row">
                    <div v-for="video in search_results.items" :key="video.id"
                     class="col-md-4">
                         <VideoElement :video="video"></VideoElement>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    const axios = require('axios')
    const API_URL = window.location.protocol+'//'+window.location.hostname+':'+window.location.port+'/'
    import VideoElement from "./VideoElement.vue"; 

    export default {
        name: "Search",
        mounted(){
             this.API_URL = this.ENVIRONNEMENT === "prod" ? API_URL : this.API_ROOT_DEV
        },
        data() {
            return {
                processing: false,
                finished: false,
                search_input: '',
                search_results: {},
                iframs: {},
                API_URL:''
            }
        },
        components: {VideoElement},
        methods: {
            getVideos() {
               if(this.search_input.length){
                this.processing = true
               const input = encodeURIComponent(this.search_input);
                axios.get(this.API_URL + 'get-videos/' + input).then(res => {
                    this.search_results = res.data
                    this.processing = false
                    this.finished = true
                })
                }
            },
            iframLoaded(id){
                this.$set(this.iframs,id,true)
            }
        }
    }
</script>
<style lang="scss">

</style>