export default {
  "Download YouTube videos for free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger des vidéos YouTube gratuitement"])},
  "Best free tool to download videos from YouTube": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meilleur outil gratuit pour télécharger des vidéos de YouTube"])},
  "Search or paste Youtube link here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherchez ou collez le lien Youtube ici"])},
  "Start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Démarrer"])},
  "The best online YouTube video downloader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le meilleur téléchargeur de vidéos YouTube en ligne"])},
  "Free tool to help you download video from Youtube to your computer for free. In addition, it supports converting to many different formats and qualities for download.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outil gratuit pour vous aider à télécharger gratuitement des vidéos de Youtube sur votre ordinateur. De plus, il prend en charge la conversion vers de nombreux formats et qualités différents pour le téléchargement."])},
  "Quick and easy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapide et facile"])},
  "Our Youtube video downloader helps you quickly search and download any Youtube video to formats like: MP4, WEBM, M4A ...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notre téléchargeur de vidéos Youtube vous aide à rechercher et télécharger rapidement n'importe quelle vidéo Youtube dans des formats tels que : MP4, WEBM, M4A..."])},
  "Unlimited YouTube downloads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléchargements YouTube illimités"])},
  "Support downloading and converting unlimited Youtube videos with the best transfer speed up to 1GB/s.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prend en charge le téléchargement et la conversion de vidéos Youtube illimitées avec la meilleure vitesse de transfert jusqu'à 1 Go/s."])},
  "Safety and Security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sûreté et sécurité"])},
  "The converted and downloaded files are kept the same quality and content as YouTube's.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les fichiers convertis et téléchargés conservent la même qualité et le même contenu que ceux de YouTube."])},
  "Support all devices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prend en charge tous les appareils"])},
  "a website-based Youtube video downloader that works well on all platforms such as Windows, Mac or Linux, Android, iPhone ... without downloading or installing any software.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["un téléchargeur de vidéos Youtube basé sur un site Web qui fonctionne bien sur toutes les plates-formes telles que Windows, Mac ou Linux, Android, iPhone ... sans télécharger ni installer de logiciel."])},
  "Supports many formats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prend en charge de nombreux formats"])},
  "Our tool supports converting many video and audio formats. You can easily convert YouTube videos to 3GP, MP4, WMA, M4A, FLV, WEBM and MO formats, etc.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notre outil prend en charge la conversion de nombreux formats vidéo et audio. Vous pouvez facilement convertir des vidéos YouTube aux formats 3GP, MP4, WMA, M4A, FLV, WEBM et MO, etc."])},
  "Free forever": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libre pour toujours"])},
  "The tool is 100% free and always will be. You can support us by sending and sharing this tool with your friends. Thank you!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'outil est 100% gratuit et le sera toujours. Vous pouvez nous soutenir en envoyant et en partageant cet outil avec vos amis. Merci!"])},
  "Online YouTube video downloader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléchargeur de vidéos YouTube en ligne"])},
  "Download Youtube videos easily with the \"Youtube Downloader\" tool. Support downloading and convert YouTube video with the best quality to formats MP4, 3GP, WEBM, M4A ... fast and completely free. This YouTube downloader application is compatible with all devices such as: computers, tablets and mobile phones ...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléchargez facilement des vidéos Youtube avec l'outil \"Youtube Downloader\". Prend en charge le téléchargement et la conversion de vidéos YouTube avec la meilleure qualité aux formats MP4, 3GP, WEBM, M4A ... rapidement et entièrement gratuitement. Cette application de téléchargement YouTube est compatible avec tous les appareils tels que : ordinateurs, tablettes et téléphones portables..."])},
  "The easiest YouTube video download guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le guide de téléchargement de vidéos YouTube le plus simple"])},
  "Paste the YouTube link or enter keywords in the search box": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collez le lien YouTube ou entrez des mots-clés dans le champ de recherche"])},
  "Select the output format you want to transfer and click the \"Download\" button.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez le format de sortie que vous souhaitez transférer et cliquez sur le bouton \"Télécharger\"."])},
  "Wait a few seconds for the conversion to complete and download the file. Simple, easy to use.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendez quelques secondes que la conversion se termine et téléchargez le fichier. Simple, facile à utiliser."])},
  "Is youtube downloader have a limit of usage?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le téléchargeur YouTube a-t-il une limite d'utilisation?"])},
  "Absolutely NO. Our tool allows you to convert and download in unlimited quantities and all for free.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absolument pas. Notre outil vous permet de convertir et de télécharger en quantités illimitées et le tout gratuitement."])},
  "Can I download videos on mobile?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puis-je télécharger des vidéos sur mobile?"])},
  "Our tool works well on all devices such as PCs, smartphones and tablets.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notre outil fonctionne bien sur tous les appareils tels que les PC, les smartphones et les tablettes."])},
  "Where is the Youtube video stored after downloading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Où est la vidéo Youtube stockée après le téléchargement"])},
  "Please check the \"Downloads\" folder in your phone or the \"download history\" section of your browser.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez vérifier le dossier «Téléchargements» de votre téléphone ou la section «Historique des téléchargements» de votre navigateur."])},
  "Frequently Asked Questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questions fréquemment posées"])},
  "What languages support ?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelles langues prennent en charge ?"])},
  "We support download Youtube video in all popular languages today.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous prenons en charge le téléchargement de vidéos Youtube dans toutes les langues populaires aujourd'hui."])},
  "About": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["à propos de nous"])},
  "Terms of Service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditions d'utilisation"])},
  "Privacy Policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politique de confidentialité"])},
  "Contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])}
}